import axiosIns from "@axios";
import helper from "@/composables/helper";

export default {
  state: {
    count: null,
    notifications: [],
    deviceToken: null,
  },
  getters: {
    getNotifications(state) {
      return state.notifications;
    },
    getNotificationsCount(state) {
      return state.count;
    },
  },
  mutations: {
    set_count(state, payload) {
      state.count = payload;
    },
    set_notification_data(state, payload) {
      state.notifications = payload;
    },
    setToken(state, payload) {
      state.deviceToken = payload;
    },
  },
  actions: {
    async get_notification({ commit }) {
      try {
        const res = await axiosIns.get("admin/notifications/unread-noti", {
          headers: {
            "X-Page-Size": 0,
          },
        });
          console.log(res)
        commit("set_count", res.data.meta.total);
        commit("set_notification_data", res.data.data);
      } catch (err) {
        helper.handleError(err);
      }
    },
    async read_all_notifications({ commit }) {
      try {
        await axiosIns.post("/admin/notifications/mark-all-as-read");
        commit("set_count", 0);
        commit("set_notification_data", []);
      } catch (err) {
        helper.handleError(err);
      }
    },
    async read_notification({ state, commit }, payload) {
      try {
        await axiosIns.put(`/admin/notifications/mark-one-asread/${payload}`);
        const newData = state.notifications.filter(
          (item) => item.id !== payload
        );
        commit("set_notification_data", newData);
        commit("set_count", state.count - 1 === 0 ? null : state.count - 1);
      } catch (err) {
        helper.handleError(err);
      }
    },
    setDeviceToken({ commit }, payload) {
      commit("setToken", payload);
    },
  },
};
